.ag-theme-alpine .ag-header-cell {
    border-right: 1px solid #AAAAAA;
}

.ag-theme-alpine .ag-ltr .ag-cell {
  border-right: 1px solid #AAAAAA;
  text-decoration: inherit;
}

.ag-row-done-style {
  text-decoration: line-through 1px black !important;
  -webkit-text-decoration-line: line-through;
}

.ag-row-priority-style {
  background-color: #FFFF88 !important;
}